// @flow
import {
  type JSSThemeShapeType,
  type JSSThemeVariablesShapeType,
  getColorCombinations,
  type ColorTypes,
  type GetJSSThemePatternsType,
  colorTypes,
} from '../JSSThemeDefault';
import { applyOnlyDesktop } from '../jss-helpers';
import { JSSThemeBetslip as betslip } from './JSSThemeBetslip64';
import { JSSThemeMyBets64 } from './JSSThemeMyBets64';
import { JSSThemeSportsMenu64 } from './JSSThemeSportsMenu64';
import { JSSThemeForm64 } from './JSSThemeForm64';
import { JSSThemeBannerItems64 } from './JSSThemeBannerItems64';
import { JSSThemeHeaderMobile64 } from './JSSThemeHeaderMobile64';
import { JSSThemeOutcomes64 } from './JSSThemeOutcomes64';
import { JSSThemeHeaderMenu64 } from './JSSThemeHeaderMenu64';
import { JSSThemeHeader64 } from './JSSThemeHeader64';
import { JSSThemeBalance64 } from './JSSThemeBalance64';
import { JSSThemeBonuses64 } from './JSSThemeBonuses64';
import { JSSThemeCoupons64 } from './JSSThemeCoupons64';
import { JSSThemeAuthForm64 } from './JSSThemeAuthForm64';
import { JSSThemePopularLeagues64 } from './JSSThemePopularLeagues64';
import { JSSTheme64Resets } from './JSSTheme64Resets';
import { JSSThemeCasino64 } from './JSSThemeCasino64';
import { JSSThemeTooltip64 } from './JSSThemeTooltip64';
import { JSSThemeSearch64 } from './JSSThemeSearch64';
import { JSSThemeAccountSection64 } from './JSSThemeAccountSection64';
import { JSSThemeFooter64 } from './JSSThemeFooter64';
import { JSSThemeLive64 } from './JSSThemeLive64';
import { JSSThemeOryx64 } from './JSSThemeOryx64';

export const colorTypes64: ColorTypes = {
  ...colorTypes,
  primary: '#ff3939',
  text1: '#fff',
  text2: '#fff',
  text3: '#333333',
  text4: '#333333',
  text5: '#eaeaea',
  background1: '#ff3939',
  background2: '#a2a2a2',
  background3: '#d12626',
  background4: '#333333',
  background5: '#484848',
  background6: '#484848', // new color
  background7: '#d1d1d1',
  shadow: 'rgba(0, 0, 0, 0.4)',
  accent: '#ff3939',
  success: '#54f763',
  error: '#ff3939',
  error2: '#d12626',
  error3: '#d12626',
  warning: 'orange',
  inform: '#68c124',
  neutral1: '#333333',
  neutral2: '#333333',
  neutral3: '#ff3939',
  neutral4: '#e6e0e0',
  border1: '#fff',
  border2: '#fefefe',
};

const pick = ({
  text3Border2,
  whitePrimary,
  text2Primary,
  whiteBg2,
  whiteBg4,
  whiteBg1,
  whiteText3,
  whiteNeutral2,
  whiteNeutral1,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteBorder1,
  whiteBorder2,
  bg4Border2,
  text3Bg4,
  text4Bg4,
  text2White,
  whiteNeutral3,
  text4Bg2,
  text2Text3,
  text3White,
  whiteBg3,
}) => ({
  text3Border2,
  whitePrimary,
  text2Primary,
  whiteBg2,
  whiteBg4,
  whiteBg1,
  whiteText3,
  whiteNeutral1,
  whiteNeutral2,
  text2Neutral2,
  whiteSuccess,
  whiteAccent,
  whiteInform,
  whiteBorder1,
  whiteBorder2,
  bg4Border2,
  text3Bg4,
  text4Bg4,
  text2White,
  whiteNeutral3,
  text4Bg2,
  text2Text3,
  text3White,
  whiteBg3,
});

export const colorCombinations64 = getColorCombinations(colorTypes64, pick);

export const JSSThemeVariables64: JSSThemeVariablesShapeType = {
  fonts: {
    default: {
      name: 'osg-open',
      fontFamily: 'osg-open, Helvetica Neue, Helvetica, Arial, sans-serif',
      config: [
        {
          path: 'Open/opensans-regular-webfont',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
        {
          path: 'Open/opensans-bold-webfont',
          fontWeight: 'bold',
          fontStyle: 'normal',
        },
      ],
    },
  },
  colors: {
    colorTypes: colorTypes64,
    colorCombinations: colorCombinations64,
  },
};

export const JSSTheme64: JSSThemeShapeType = (themeVars) => {
  const {
    colors: {
      colorCombinations: {
        whiteBg2,
        whiteBorder1,
        whitePrimary,
        text3White,
        whiteBg3,
      },
      colorTypes: {
        primary,
        white,
        neutral1,
        background4,
        text5,
        text3,
        background3,
        background5,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    Heading: {
      common: {
        background: 'transparent',
        color: white,
        fontSize: fontSizes.xxxl,
        letterSpacing: 'normal',
        padding: [margins.xs, margins.md, 0],
      },
    },
    mybets: JSSThemeMyBets64(themeVars),
    Button: {
      root: {
        borderRadius: '2px',
        lineHeight: '32px',
        padding: '0 10px',
        fontWeight: 'bold',
        fontSize: 12,
        ...whiteBg2,
        ...applyOnlyDesktop({
          '&:hover': whiteBorder1,
        }),
      },
      block: {
        width: '100%',
      },
      primary: {
        ...whitePrimary,
        boxShadow: '0 5px 15px #ff3939bf',
        ...applyOnlyDesktop({
          '&:hover': {
            ...whiteBg3,
          },
        }),
      },
      linkSecondary: {
        '&:hover': {
          background: 'transparent',
        },
      },
      secondary: {
        background: 'transparent',
        color: white,
        '&:hover': {
          background: 'transparent',
          color: primary,
        },
      },
      accent: {
        background: primary,
        transition: '.3s ease-in',
        boxShadow: '0 5px 15px #ff3939bf',
        '&:hover': {
          background: background3,
          boxShadow: '0 5px 15px #ff3939bf',
        },
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
      linkAccent: {
        color: primary,
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
            background: 'transparent',
          },
        }),
      },
      default: {
        ...text3White,
        '&:hover': {
          color: text3,
          background: text5,
        },
      },
    },
    betslip: betslip(themeVars),
    NoBetsMessage: {
      root: {
        background: background5,
        color: white,
        fontWeight: 'normal',
        fontSize: fontSizes.sm,
      },
    },
    Geoblock: {
      logoGeoblock: {
        background: text3,
      },
      rootGeoblock: {
        background: background5,
      },
    },
    sportsMenu: JSSThemeSportsMenu64(themeVars),
    form: JSSThemeForm64(themeVars),
    headerMobile: JSSThemeHeaderMobile64(themeVars),
    banners: JSSThemeBannerItems64(themeVars),
    headerMenu: JSSThemeHeaderMenu64(themeVars),
    outcomes: JSSThemeOutcomes64(themeVars),
    header: JSSThemeHeader64(themeVars),
    authForm: JSSThemeAuthForm64(themeVars),
    balance: JSSThemeBalance64(themeVars),
    bonuses: JSSThemeBonuses64(themeVars),
    coupons: JSSThemeCoupons64(themeVars),
    popularLeagues: JSSThemePopularLeagues64(themeVars),
    casino: JSSThemeCasino64(themeVars),
    search: JSSThemeSearch64(themeVars),
    footer: JSSThemeFooter64(themeVars),
    tooltip: JSSThemeTooltip64(themeVars),
    live: JSSThemeLive64(themeVars),
    accountSection: JSSThemeAccountSection64(themeVars),
    oryx: JSSThemeOryx64(themeVars),
    LiveCalendar: {
      common: {
        margin: 0,
      },
      dateTitle: {
        color: '#000',
      },
      link: {
        color: background3,
      },
      dateEventNoMarkets: {
        '&:hover': {
          background: '#fff',
          color: '#000',
        },
      },
      dateEvent: {
        color: '#000',
        '&:hover': {
          background: '#eee',
        },
      },
    },
    BetslipMyBetsPanelTitle: {
      heading: {
        boxShadow: `none`,
        background: 'transparent',
        color: white,
      },
    },
    BetslipMyBetsStakePotentialTotalUI: {
      stakePotentialWrapper: {
        color: white,
        borderTop: `1px solid ${neutral1}`,
      },
    },
    BetslipMyBetsPanelUI: {
      panel: {
        background: background4,
        borderRadius: '2px',
      },
    },
    resets: JSSTheme64Resets(themeVars),
    OfflineMessage: {
      popup: {
        color: text3,
      },
    },
  };
};

export const getJSSThemePatterns64: GetJSSThemePatternsType = (themeVars) => {
  const {
    colors: {
      colorCombinations: {
        whiteNeutral3,
        whitePrimary,
        whiteAccent,
        text2Neutral2,
        whiteBg4,
        whiteBg1,
      },
    },
    fontSizes,
    margins,
  } = themeVars;
  return {
    buttons: {
      primary: {
        ...whitePrimary,
        ...applyOnlyDesktop({
          '&:hover': whiteNeutral3,
        }),
      },
      secondary: {
        ...text2Neutral2,
        ...applyOnlyDesktop({
          '&:hover': whiteBg4,
        }),
      },
      accent: {
        ...whiteAccent,
        ...applyOnlyDesktop({
          '&:hover': whiteBg1,
        }),
      },
      large: {
        lineHeight: '42px',
        fontSize: fontSizes.xl,
      },
      small: {
        padding: [margins.xs, margins.md],
      },
    },
  };
};
