// @flow
import { type JSSThemeDefaultTooltipType } from '../JSSThemeDefaultTooltip';

export const JSSThemeTooltip64: JSSThemeDefaultTooltipType = themeVars => {
  const {
    colors: {
      colorCombinations: { text3White },
    },
  } = themeVars;
  return {
    Tooltip: {
      tooltipText: {
        ...text3White,
      },
    },
  };
};
