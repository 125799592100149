// @flow
import { type JSSThemeDefaultCouponsType } from '../JSSThemeDefaultCoupons';

export const JSSThemeCoupons64: JSSThemeDefaultCouponsType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: { white, background5, background3 },
    },
  } = themeVars;
  return {
    MobileWidgetCouponView: {
      sportLabel: {
        color: white,
      },
      sportInnerLink: {
        color: white,
      },
      sportHeader: {
        '&:first-child': {
          marginTop: 0,
        },
      },
    },
    SportTabs: {
      tabs: {
        padding: margins.md,
      },
      tab: {
        color: white,
        background: background5,
        '&:hover': {
          color: white,
        },
      },
    },
    WidgetSport: {
      sport: {
        margin: [0, margins.lg],
      },
    },
    Market: {
      root: {
        borderTop: 'none',
      },
    },
    EventPrimaryMarketOutcomesMobile: {
      date: {
        opacity: '.6',
      },
    },
    OutcomesCounter: {
      outcomeCount: {
        background: 'transparent',
        fontWeight: 'bold',
        color: background5,
        fontSize: fontSizes.sm,
        paddingRight: margins.xs,
        '&:hover': {
          background: 'transparent',
          color: background3,
        },
      },
    },
    Betbuilder: {
      exampleContainer: {
        background: white,
        color: '#000',
      },
    },
  };
};
