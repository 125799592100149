// @flow
import { type JSSThemeDefaultMyBetsType } from '../JSSThemeDefaultMyBets';

export const JSSThemeMyBets64: JSSThemeDefaultMyBetsType = themeVars => {
  const {
    margins,
    fontSizes,
    colors: {
      colorCombinations: { text3Border2 },
      colorTypes: { background5, white, accent, neutral4, text3 },
    },
  } = themeVars;
  return {
    ConfirmationBox: {
      confirmation: {
        background: background5,
        padding: [margins.xm, margins.md, margins.xs],
        marginTop: margins.md,
      },
      text: {
        color: white,
        fontSize: fontSizes.sm,
        fontWeight: 'normal',
      },
      loading: {
        color: white,
      },
      buttonNo: {
        padding: 0,
      },
    },
    CashOut: {
      success: {
        ...text3Border2,
        borderRadius: 2,
      },
    },
    MyBets: {
      content: {
        background: background5,
      },
      myBetsTabsItem: {
        background: neutral4,
        color: text3,
        transition: '.3s ease-in',
        '&:hover, &.active': {
          background: accent,
          color: white,
        },
      },
    },
  };
};
