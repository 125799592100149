// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme64,
  JSSThemeVariables64,
  getJSSThemePatterns64,
} from './JSSTheme64';
import { GeoblockPage64 } from './GeoblockPage64';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop64')).FullDesktop64,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () => import('./FullMobile64').then(module => module.FullMobile64),
  }),
  JSSTheme: JSSTheme64,
  JSSThemeVariables: JSSThemeVariables64,
  JSSThemePatterns: getJSSThemePatterns64,
  GeoblockPage: GeoblockPage64,
});
