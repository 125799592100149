// @flow
import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu64: JSSThemeDefaultSportsMenuType = themeVars => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: {
        text3,
        neutral1,
        white,
        primary,
        background5,
        background1,
        accent,
      },
    },
  } = themeVars;

  return {
    HorizontalSportsListUIBigIcons: {
      list: {
        background: text3,
      },
      sportLink: {
        padding: 0,
        color: neutral1,
      },
    },
    HorizontalSportsListUISmallIcons: {
      sportLink: {
        color: white,
        padding: [margins.xs, margins.xs, margins.xs, 0],
        fontWeight: 'bold',
      },
    },
    HorizontalSportsListIconSmall: {
      sportIcon: {
        fontSize: fontSizes.xl,
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
      sportName: {
        fontWeight: 'bold',
        color: white,
        '&.active': {
          color: primary,
        },
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        color: primary,
        background: white,
      },
      title: {
        color: white,
      },
    },
    SportsMenuDesktop: {
      sport: {
        background: background5,
        '&.active': {
          background: background1,
        },
      },
      region: {
        background: 'transparent',
        padding: [margins.xs, margins.md, margins.xs, 34],
        fontSizes: fontSizes.xxl,
        '&.active': {
          background: accent,
        },
      },
      main: {
        background: background5,
        padding: [margins.lg, 0, margins.lg, 0],
        borderRadius: [0, 0, margins.md, margins.md],
      },
    },
  };
};
