// @flow
import { type JSSThemeDefaultAccountSectionType } from '../JSSThemeDefaultAccountSection';

export const JSSThemeAccountSection64: JSSThemeDefaultAccountSectionType = themeVars => {
  const {
    margins,
    colors: {
      colorTypes: { text2, text3, background5, white },
    },
  } = themeVars;
  return {
    OPMProvider: {
      providerRoot: {
        boxShadow: 'none',
        color: text2,
        background: text3,
        border: 'none',
        '&:first-child': {
          // marginLeft: 0,
        },
      },
      logoImg: {
        background: white,
      },
    },
    OPMForms: {
      logoImg: {
        background: white,
      },
    },
    PendingWithdrawal: {
      heading: {
        padding: [0, 0, margins.md],
        margin: 0,
      },
      wrapper: {
        margin: [margins.lg, margins.xm],
      },
    },
    OPMPageComponent: {
      OPMroot: {
        margin: [0, margins.xm],
      },
    },
    AccountPageDesktop: {
      accountPageRightContent: {
        background: background5,
      },
    },
  };
};
