// @flow
import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';

export const JSSThemeFooter64: JSSThemeDefaultFooterType = themeVars => {
  const {
    colors: {
      colorTypes: { text2, text3 },
    },
  } = themeVars;
  return {
    FooterDesktop: {
      footer: {
        background: text3,
      },
      link: {
        color: text2,
      },
      heading: {
        color: text2,
      },
    },
    FooterMobile: {
      footer: {
        background: text3,
      },
      link: {
        color: text2,
      },
      heading: {
        color: text2,
      },
    },
  };
};
