// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeOryx64 = (themeVars: typeof JSSThemeVariables) => {
  const {
    colors: {
      colorTypes: { background2, white },
    },
  } = themeVars;

  return {
    OryxMobile: {
      toggle: {
        color: white,
        backgroundColor: background2,
      },
    },
  };
};
