// @flow
import { type JSSThemeDefaultHeaderMenuType } from '../JSSThemeDefaultHeaderMenu';
export const JSSThemeHeaderMenu64: JSSThemeDefaultHeaderMenuType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { primary, white },
    },
  } = themeVars;
  return {
    HeaderMenuDesktop: {
      menuWrapper: {
        background: 'none',
        fontSize: 15,
        '@media screen and (max-width: 1100px)': {
          fontSize: 12,
        },
      },
      menuItemWrapper: {
        borderRight: 'none',
      },
      menuItem: {
        fontWeight: 'bold',
        color: white,
        '&:hover, &:focus': {
          color: primary,
        },
      },
      menuItemActive: {
        color: primary,
      },
    },
  };
};
