// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile64: JSSThemeDefaultHeaderMobileType = themeVars => {
  const {
    colors: {
      colorTypes: { text3, text2, text4 },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: text3,
        boxShadow: `inset 0px -1px ${text4}`,
      },
      iconContainer: {
        color: text2,
      },
      menuContainer: {
        background: '#333',
      },
      logoImg: {
        width: 'auto',
        height: 22,
        margin: 3,
      },
    },
  };
};
